const data = [{
  getBusinessInfo: {
    status: true,
    messages: null,
    data: {
      _id: '2001357321571078475945710100',
      creationDate: '2018-05-21T14:54:20.427+0000',
      nameInformation: [
        {
          name: 'OTB',
          typeCode: 'TRADENAME',
        },
        {
          name: 'Cajun Project Llc',
          typeCode: 'ENTITYNAME',
        },
      ],
      addressInformation: [
        {
          map_link: [
            {
              title: 'Google StreetView',
              url: 'http://maps.google.com/maps?q=&layer=c&cbll=40.710536,-73.963396&cbp=11,0,0,0,0',
            },
            {
              title: 'NYCMap',
              url: 'http://maps.nyc.gov/doitt/nycitymap/?z=9&p=994398,198146&c=GISBasic&s=a:141,BROADWAY,BROOKLYN',
            },
          ],
          fullAddress: '141 Broadway, Brooklyn NY 11211-8756, United States',
        },
      ],
    },
  },
},
];

export default data;
