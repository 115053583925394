<template>
    <div class="swagger" id="swagger"></div>
</template>

<script>
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';

export default {
  name: 'Swagger',
  mounted() {
    const spec = require('../store/terrenelabs_api_test-2.6-swagger.json'); // eslint-disable-line
    SwaggerUI({
      spec,
      dom_id: '#swagger',
    });
  },
};
</script>

<style>
.v-application .swagger-ui .info {
    background: none!important;
}
</style>
