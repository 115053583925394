<template>
  <div class="api">
    <v-navigation-drawer
      app
      width="200"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            <v-img
              src="../assets/api_logo.png"
              width="18"
              alt="API Logo"
              class="d-inline-block"
            ></v-img> API
          </v-list-item-title>
          <v-list-item-subtitle>
            Underwriting Insights
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-treeview
        dense
        v-model="tree"
        :open="initiallyOpen"
        :items="tree"
        activatable
        item-key="name"
        open-all
        open-on-click
      >
        <template v-slot:label="{ item }">
          <span @click="$vuetify.goTo(item.link, options)">{{ item.name }}</span>
        </template>

        <template v-slot:prepend="{ item, open }">
          <v-icon small v-if="!item.file">
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
          <v-icon small v-else>
            {{ files[item.file] }}
          </v-icon>
        </template>
      </v-treeview>
    </v-navigation-drawer>

    <v-container>
      <v-row>
        <v-col>
          <v-breadcrumbs :items="items"></v-breadcrumbs>

          <v-btn
            dark
            fab
            tile
            small
            v-on:click="codeView = !codeView"
            class="codeExpandBtn"
          >
            <v-icon>mdi-arrow-expand-all</v-icon>
          </v-btn>

          <v-text-field
            v-model="apiKey"
            label="API Key"
          >
          </v-text-field>

          <h1 class="font-weight-light" id="introduction">FRISS Underwriting Insights REST APIs</h1>
          <v-divider></v-divider>

          <blockquote class="blockquote mb-6">
            FRISS Underwriting Insights APIs enable you to jump start your application development
            with a powerful set of AI driven REST APIs that give rich underwriting insight.
          </blockquote>

          <h2 class="font-weight-light" id="authorization">Authorization</h2>
          <v-divider></v-divider>
          <p class="my-6">
            All of our API endpoints utilize industry standard
            <strong>Bearer authentication</strong>.
            <br><br>
            <strong>Bearer authentication</strong> (also called
            <strong>token authentication</strong>) is an
            <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Authentication" target="_blank">
              HTTP authentication scheme
            </a> that involves security
            tokens called bearer tokens. The name “Bearer authentication”
            can be understood as “give access to the bearer of this token.”
            The bearer token is a cryptic string, usually generated by the
            server in response to a login request. The client must send this
            token in the <code>Authorization</code> header when making requests to
            protected resources:
            <br><br>
            Below is an example of this authorization:
          </p>
          <code>Authorization: Bearer {{ apiKey }}</code>

          <h2 class="font-weight-light" id="appetite">Appetite</h2>
          <p>You make the best underwriting decisions for your appetite.
            Leave the chore of information collection to us.
            Our solutions require as little as one field of data to
            return insights that previously went unseen.</p>

          <h2 class="font-weight-light" id="profile">Profile</h2>
          <p>You make the best underwriting decisions for your appetite.
            Leave the chore of information collection to us.
            Our solutions require as little as one field of data to return
            insights that previously went unseen.</p>

          <h2 class="font-weight-light" id="profile-pdf">Profile PDF</h2>
          <p>You make the best underwriting decisions for your appetite.
            Leave the chore of information collection to us.
            Our solutions require as little as one field of data to return
            insights that previously went unseen.</p>

          <h2 class="font-weight-light" id="profile-acord">Profile ACORD</h2>
          <p>ACORD forms provide the Insurance carriers a standardized format
            of risk information submission and currently requires
            manual entry of form data into carrier systems. Underwriting
            AI makes the process fully automated.</p>

          <h2 class="font-weight-light" id="batch-loss-run">Batch Loss Run</h2>
          <p>Loss Runs require underwriters to sift through prior
            carrier reports  to understand the risk.
            Underwriting AI digitizes and standardizes them.</p>

          <!-- <h2 class="font-weight-light mt-6" id="api-key">Development API Secret Key</h2>
          <v-divider></v-divider>
          <p class="my-6">
            This is your development API secret key tied to your account.
            Please keep this safe. It will be needed for all service calls
            to the FRISS Underwriting Insights API.
          </p>
          <small>apiKey: </small>
          <code>{{ apiKey }}</code> -->

          <h2 class="font-weight-light mt-6" id="dev-api">Development API Endpoint</h2>
          <v-divider></v-divider>
          <p class="my-6">
            The following is the development API endpoint for testing.
            The test API account and development endpoint will only return
            prepared responses based on the selection of inputs attached to
            the account to support the needs of the client.
          </p>
          <p>
            These are the endpoints for the Profile service. Other services
            beyond the intended scope of the interface agreement with your
            business have different endpoints.
          </p>
          <code>https://api-test.terrenelabs.com/business/v2/profile/</code>

          <h2 class="font-weight-light mt-6" id="sample-request">Sample Request (curl)</h2>
          <p>
            Below is a sample command to execute the service against the
            development API endpoint with your development API secret key.
          </p>

          <prism-editor
            class="my-editor"
            v-model="curlData"
            :highlight="highlighterCurl"
            :line-numbers="lineNumbers"
          >
          </prism-editor>

          <p class="mt-6" id="postman">
            Additionally, included is a Postman collection,
            configured with a sample API key and a sample Profile call.
          </p>

          <a href="https://app.getpostman.com/run-collection/f591bdd948aded58d17a" target="_blank">
            <v-img width="150" src="https://run.pstmn.io/button.svg"></v-img>
          </a>

          <h2 class="font-weight-light mt-6" id="sample-success-response">
            Sample Success Response
          </h2>
          <p>
            Note: Any fields without values will be absent
            from the response message as opposed to present and null.
          </p>

          <json-viewer
            :value="jsonData"
            :expand-depth=5
            copyable
            theme="json-theme"
          >
          </json-viewer>

          <h2 class="font-weight-light mt-6" id="sample-failure-response">
            Sample Failure Response
          </h2>

          <json-viewer
            :value="jsonData"
            :expand-depth=5
            copyable
            theme="json-theme"
          >
          </json-viewer>

          <h2 class="font-weight-light mt-6" id="prod-api">Production API Endpoint</h2>
          <v-divider></v-divider>
          <p class="my-6">
            The following is the production API endpoint for production enviroments.
          </p>

          <code>https://api.terrenelabs.com/business/v2/profile/</code>

          <h2 class="font-weight-light mt-6" id="swagger">Swagger</h2>
          <v-divider></v-divider>
          <SwaggerUI />
        </v-col>
      </v-row>
    </v-container>

    <v-navigation-drawer
      v-if="codeView"
      width="550"
      app
      dark
      right
    >

      <v-list>
        <h2 class="title px-5">Sample Request</h2>
      </v-list>

      <v-tabs
        v-model="tab"
        flat
        height="35"
      >
        <v-tab>CURL</v-tab>
        <v-tab>Python</v-tab>
        <v-tab>C#</v-tab>
        <v-tab>JAVA</v-tab>
      </v-tabs>

      <v-divider></v-divider>

      <v-tabs-items
        dark
        v-model="tab"
      >
        <v-tab-item>
          <prism-editor
            class="my-editor"
            style="max-width: 90%"
            v-model="curlData"
            :highlight="highlighterCurl"
            :line-numbers="lineNumbers"
          ></prism-editor>
          <v-btn
            fab
            small
            light
            class="copyBtn"
            v-on:click="snackbar = !snackbar"
            v-clipboard:copy="curlData"
          >
            <v-icon small>mdi-content-copy</v-icon>
          </v-btn>
        </v-tab-item>
        <v-tab-item>
          <prism-editor
            class="my-editor"
            v-model="pythonSample"
            :highlight="highlighterPython"
            :readonly="true"
            :line-numbers="lineNumbers"
          ></prism-editor>
          <v-btn
            fab
            small
            light
            class="copyBtn"
            v-on:click="snackbar = !snackbar"
            v-clipboard:copy="pythonSample"
          >
            <v-icon small>mdi-content-copy</v-icon>
          </v-btn>
        </v-tab-item>
        <v-tab-item>
          <prism-editor
            class="my-editor"
            v-model="cSample"
            :highlight="highlighterC"
            :line-numbers="lineNumbers"
          ></prism-editor>
          <v-btn
            fab
            small
            light
            class="copyBtn"
            v-on:click="snackbar = !snackbar"
            v-clipboard:copy="cSample"
          >
            <v-icon small>mdi-content-copy</v-icon>
          </v-btn>
        </v-tab-item>
        <v-tab-item>
          <prism-editor
            class="my-editor"
            v-model="javascriptData"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          ></prism-editor>
          <v-btn
            fab
            small
            light
            class="copyBtn"
            v-on:click="snackbar = !snackbar"
            v-clipboard:copy="javascriptData"
          >
            <v-icon small>mdi-content-copy</v-icon>
          </v-btn>
        </v-tab-item>
      </v-tabs-items>

    </v-navigation-drawer>

    <v-snackbar
      v-model="snackbar"
    >
      Copied to clipboard
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// Swagger Component
import SwaggerUI from '@/components/SwaggerUI.vue';

// import Prism Editor
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-bash';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

// JSON Viewer
import 'vue-json-viewer/style.css';
import data from '../code-examples/sample';
// import jsData from '../code-examples/sample-js';
// import pythonData from '../code-examples/sample-python';
// import cData from '../code-examples/sample-c';

export default {
  name: 'API',
  metaInfo: {
    title: 'API',
  },
  data: () => ({
    snackbar: false,
    codeView: true,
    apiKey: '04a13e92-9d15-4455-bb1a-148173d77a75',
    jsonData: data,
    // javascriptData: jsData,
    // pythonSample: pythonData,
    // cSample: cData,
    tab: null,
    lineNumbers: true,
    duration: 300,
    offset: 10,
    easing: 'easeInOutCubic',
    items: [
      {
        text: 'AppHub',
        disabled: false,
        to: '/',
      },
      {
        text: 'API',
        disabled: true,
        to: 'api',
      },
    ],
    initiallyOpen: ['public'],
    files: {
      html: 'mdi-language-html5',
      js: 'mdi-nodejs',
      json: 'mdi-code-json',
      md: 'mdi-language-markdown',
      pdf: 'mdi-file-pdf',
      png: 'mdi-file-image',
      txt: 'mdi-file-document-outline',
      xls: 'mdi-file-excel',
    },
    tree: [
      {
        name: 'Get started',
        children: [
          {
            name: 'Introduction',
            file: 'txt',
            link: '#introduction',
          },
          {
            name: 'Authorization',
            file: 'txt',
            link: '#authorization',
          },
          {
            name: 'Development API',
            file: 'txt',
            link: '#dev-api',
          },
          {
            name: 'Production API',
            file: 'txt',
            link: '#prod-api',
          },
        ],
      },
      {
        name: 'API Endpoints',
        file: 'txt',
        children: [
          {
            name: 'Appetite',
            file: 'txt',
            link: '#appetite',
          },
          {
            name: 'Profile',
            file: 'txt',
            link: '#profile',
          },
          {
            name: 'Profile PDF',
            file: 'txt',
            link: '#profile-pdf',
          },
          {
            name: 'Profile ACORD',
            file: 'txt',
            link: '#profile-acord',
          },
          {
            name: 'Batch Loss Run',
            file: 'txt',
            link: '#batch-loss-run',
          },
        ],
      },
      {
        name: 'Postman',
        file: 'txt',
        link: '#postman',
      },
      {
        name: 'Swagger',
        file: 'txt',
        link: '#swagger',
      },
    ],
  }),
  computed: {
    curlData() { return `curl -H "Authorization: Bearer ${this.apiKey}" -G "https://api-test.terrenelabs.com/business/v2/profile/" --data-urlencode "name=BORDNER 2 INVESTMENTS LLC" --data-urlencode "dba=Cardo's Pizza & Tavern" --data-urlencode "street=7897 Refugee Rd" --data-urlencode "zip=43147" --data-urlencode "phone=6148348101"`; },
    cSample() {
      return (`using RestSharp;

var client = new RestClient("https://api-test.terrenelabs.com/business/v2/profile");
client.Timeout = -1;

var request = new RestRequest(Method.GET);
request.AddHeader("Authorization", "Bearer ${this.apiKey}");
request.AddParameter("name", "Suntech Consulting");
request.AddParameter("street", "42215 Washington St");
request.AddParameter("zip", "92211");
request.AddParameter("phone", "7602754749");

IRestResponse response = client.Execute(request);
Console.WriteLine(response.Content);`);
    },
    pythonSample() {
      return (`import requests

url = "https://api-test.terrenelabs.com/business/v2/profile"
headers = {
    'Authorization': 'Bearer ${this.apiKey}'
}
params = {
    'name': 'Suntech Consulting',
    'street': '42215 Washington St',
    'zip': '92211',
    'phone': '7602754749',
}
response = requests.get(url, headers=headers, params=params)
print(response.json())`);
    },
    javascriptData() {
      return (`URL url = new URL("https://api-test.terrenelabs.com/business/v2/profile");

HttpURLConnection conn = (HttpURLConnection) url.openConnection();

conn.setRequestProperty("Authorization","Bearer "+" ${this.apiKey}");
conn.setRequestProperty("Content-Type","application/json");
conn.setRequestMethod("GET");
conn.setDoOutput(true);

DataOutputStream dos = new DataOutputStream(con.getOutputStream());

dos.writeChars("name=Suntech Consulting&street=42215 Washington St&zip=92211&phone=7602754749");
dos.flush();
dos.close();

BufferedReader in = new BufferedReader(new InputStreamReader(conn.getInputStream()));
String output;

StringBuffer response = new StringBuffer();
while ((output = in.readLine()) != null) {
    response.append(output);
}

in.close();
System.out.println("Response:-" + response.toString());`);
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },
  },
  components: {
    PrismEditor,
    SwaggerUI,
  },
  methods: {
    highlighter(jsonData) {
      return highlight(jsonData, languages.java);
    },
    highlighterCurl(curlData) {
      return highlight(curlData, languages.bash);
    },
    highlighterC(cSample) {
      return highlight(cSample, languages.csharp);
    },
    highlighterPython(pythonSample) {
      return highlight(pythonSample, languages.python);
    },
  },
};
</script>

<style lang="scss">
.theme--dark.v-navigation-drawer, .theme--dark.v-tabs-items {
  background: #1d262f!important;
}

.codeExpandBtn {
  position: absolute!important;
  top: 1rem;
  right: 1rem;
  border-radius: 3px!important;
}

.my-editor {
  background: #1d262f;
  color: #ccc;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  height: auto;
  margin-bottom: 1rem;
  border-radius: 3px;
}

.prism-editor__textarea:focus {
  outline: none;
}

.copyBtn {
  position: absolute!important;
  top: 1rem;
  right: 1rem;
}

.json-theme {
  background: #1d262f;
  white-space: nowrap;
  color: #ccc;
  font-size: 14px;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  line-height: 1.5;
  border-radius: 3px;

  .jv-ellipsis {
    color: #999;
    background-color: #111;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 0px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }
  .jv-button { color: #49b3ff }
  .jv-key { color: #ccc }
  .jv-item {
    &.jv-array { color: #ccc }
    &.jv-boolean { color: #fc1e70 }
    &.jv-function { color: #067bca }
    &.jv-number { color: #fc1e70 }
    &.jv-number-float { color: #fc1e70 }
    &.jv-number-integer { color: #fc1e70 }
    &.jv-object { color: #ccc }
    &.jv-undefined { color: #e08331 }
    &.jv-string {
      color: #7ec699;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #eee;
        }
      }
    }
  }
}
</style>
